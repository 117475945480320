import React from "react";

import "../styles/centered-box.less";
import "../styles/offres.less";

import { getLocalFormValues, navigate } from "../utils";

import { Layout, Row, Col, Button } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

export default function SpeedForm() {
  const initFormValues = getLocalFormValues();

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    return navigate("/");
  }

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Aucune offre disponible</h2>
            <Row gutter={16}>
              <p>
                Malheureusement, nous ne sommes pas encore en mesure d'assurer
                les véhicules roulant au-delà de 25km/h.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Nous vous communiquerons cette information dès que ce sera le
                cas.
              </p>
              <Col span={24}>
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() => navigate(-1)}
                >
                  Corriger
                </Button>
                <Button onClick={() => navigate("/")} type="primary">
                  Retour à l'accueil
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
